<template>
    <div>
        <nav class="site-header sticky-top py-1">

            <div class="container d-flex flex-column flex-md-row justify-content-between">
                <a href="/">
                    <img src="2_sp_g.png" style="margin-bottom: auto;margin-top: auto" height="32" width="48"/>
                </a>
                <div class="py-2 d-none d-md-inline-block">
                    <router-link :to="{name:'home'}">Start</router-link>
                </div>
                <a class="py-2 d-none d-md-inline-block">
                    <router-link :to="{name:'blog'}">Blog</router-link>
                </a>
                <a class="py-2 d-none d-md-inline-block">
                    <router-link :to="{name:'impress'}">Features</router-link>
                </a>
                <a class="py-2 d-none d-md-inline-block"
                   href="https://docs.google.com/forms/d/e/1FAIpQLSck0xfXmu2mEOfhzEwWTUUiKgACGmgKWFBhQ1oVtaGFb1P07g/viewform">Zugang
                    Anfragen</a>
                <div class="py-2 d-none d-md-inline-block">
                    <router-link :to="{name:'impress'}">Impressum</router-link>
                </div>
            </div>
        </nav>


        <router-view></router-view>
        <footer class="container py-5">
            <div class="row">

                <div class="col-12 col-md">
                    <h5>Seiten</h5>
                    <ul class="list-unstyled text-small">
                    <li><router-link :to="{name:'blog'}">Blog</router-link></li>
                    <li><a class="py-2 d-none d-md-inline-block"
                       href="https://docs.google.com/forms/d/e/1FAIpQLSck0xfXmu2mEOfhzEwWTUUiKgACGmgKWFBhQ1oVtaGFb1P07g/viewform">Zugang
                        Anfragen</a></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Features</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="text-muted" href="#">Trainingsplanung</a></li>
                        <li><a class="text-muted" href="#">Anwesenheitsliste</a></li>
                        <li><a class="text-muted" href="#">Ideen zum Corona Training</a></li>
                        <li><a class="text-muted" href="#">Übungssammlung</a></li>
                        <li><a class="text-muted" href="#">Statistiken</a></li>
                    </ul>
                </div>


                <div class="col-6 col-md">
                    <h5>Über Uns</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="text-muted" href="#">Team</a></li>
                        <li>
                            <div class="text-muted">
                                <router-link :to="{name:'impress'}">Impressum / Datenschutz</router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>


    export default {
        name: 'App',
        components: {}
    }
</script>

<style>
    .container {
        max-width: 960px;
    }

    /*
     * Custom translucent site header
     */

    .site-header {
        background-color: #2F855A;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }

    .site-header a {
        color: white;
        transition: ease-in-out color .15s;
    }

    .site-header a:hover {
        color: #fff;
        text-decoration: none;
    }

    /*
     * Dummy devices (replace them with your own or something else entirely!)
     */

    .product-device {
        position: absolute;
        right: 10%;
        bottom: -30%;
        width: 300px;
        height: 540px;
        background-color: #333;
        border-radius: 21px;
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }

    .product-device::before {
        position: absolute;
        top: 10%;
        right: 10px;
        bottom: 10%;
        left: 10px;
        content: "";
        background-color: rgba(255, 255, 255, .1);
        border-radius: 5px;
    }

    .product-device-2 {
        top: -25%;
        right: auto;
        bottom: 0;
        left: 5%;
        background-color: #e5e5e5;
    }


    /*
     * Extra utilities
     */

    .flex-equal > * {
        -ms-flex: 1;
        flex: 1;
    }

    @media (min-width: 768px) {
        .flex-md-equal > * {
            -ms-flex: 1;
            flex: 1;
        }
    }

    .overflow-hidden {
        overflow: hidden;
    }

</style>

