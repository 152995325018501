
import {
    BlogPostClient,
    TextContentsClient,
    LandingPagesTeasersClient
} from "@/components/api/api.clients";

const apiHost = "https://api.kinder-fussball-karlsruhe.de";
/*const fetchWrapper: { fetch: typeof window.fetch } = {
    async fetch(request, init?): Promise<Response> {
        const client =await  getAuthenticationClient();
        const token = await client.getTokenSilently() as string;

        return window.fetch(request, {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: `Bearer ${token}`
            }
        });
    }
};

//export const statusClient = new StatusClient(apiHost, fetchWrapper);
*/

export const landingPagesTeasersClient = new LandingPagesTeasersClient(apiHost)
export const blogPostsClient = new BlogPostClient(apiHost);
export const textContentsClient = new TextContentsClient(apiHost);


