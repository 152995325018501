











































// @ is an alias to /src
import {landingPagesTeasersClient} from "@/components/api/clients.context";

import {Component, Vue} from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import {TeaserBlock} from "@/components/api/api.clients";

@Component
export default class home extends Vue {

  teasers: TeaserBlock[] | null = null

  async mounted() {
    this.teasers = await landingPagesTeasersClient.forPrimaryPage();
  }

}
